<template>
	<div>
		<div class="list-attachment">
			<div class="attachment-item pointer mb-1 flex" :style="fontColor" v-for="(item, index) in attachments" :key="index">
				<div class="attachment-icon"><vs-icon icon="link"></vs-icon></div>
				<div class="text-ellipsis" @click="openFile(item)">{{ hideFileExtension(item.name) }} </div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'AttachmentList',
  components: {
  },
  props: {
    attachments: {
      type: Array,
      default: () => [],
    },
    eventSettings: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    fontColor() {
      const color = this.eventSettings && this.eventSettings.style && this.eventSettings.style.font_color;
      if (!color) return '';
      const styles = {
        color: `${color}`,
      };
      return styles;
    },
  },
  methods: {
    hideFileExtension(fileName) {
      return fileName.replace(/\.[^/.]+$/, '');
    },
    openFile(item) {
      window.open(item.file_path, '_blank');
    },
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
